import './impressum.scss';
import React from "react";

function Impressum() {

    return <React.Fragment>
        <div className={"impressum"}>
            <h2>Impressum</h2>
            <div className={"card"}>
                <div className={"text"}>
                    <p>In case of need reach out to us via e-mail</p>
                    <p>admin@btcleaks.com</p>
                    <p></p>
                </div>
            </div>
            <div className={"horse"}/>
        </div>
    </React.Fragment>;

}

export default Impressum;
